import queryString from "query-string";
import config from "../config";
const nonce = require("nonce")();

function Shopify({ location, cookies }) {
  const { shop } = queryString.parse(location.search);
  const scopes = "read_orders,read_customers,read_products";
  const state = nonce();
  const forwardingAddress = "https://" + window.location.hostname;
  const redirectUri = forwardingAddress + "/shopify/callback";
  const installUrl =
    "https://" +
    shop +
    "/admin/oauth/authorize?client_id=" +
    config.shopifyApiKey +
    "&scope=" +
    scopes +
    "&state=" +
    state +
    "&redirect_uri=" +
    redirectUri;

  cookies.set("state", state, {
    path: "/",
    sameSite: "none",
    secure: true,
  });
  window.location = installUrl;
  return null;
}

export default Shopify;
