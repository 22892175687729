import React, { useContext, useEffect, useState, useCallback } from "react";
import {
  Page,
  Layout,
  Card,
  TextContainer,
  Button,
  Autocomplete,
  Tag,
  Stack,
  Badge,
  Modal,
  SettingToggle,
  TextStyle,
  Select,
  FormLayout,
  CalloutCard,
} from "@shopify/polaris";
import { getTemplates } from "../data/apiFunctions";

import "../styles/OrderDetail.css";
import { Context } from "../data/Store";

const TemplateDetail = (props) => {
  const [state, dispatch] = useContext(Context);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [deleteIntention, setDeleteIntention] = useState(false);

  const [active, setActive] = useState(false);
  const [selectedSender, setSelectedSender] = useState("default");
  const [selectedSendGridTemplate, setselectedSendGridTemplate] = useState({});

  const [productInputText, setProductInputText] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [deselectedProducts, setDeselectedProducts] = useState([]);

  const [collectionInputText, setCollectionInputText] = useState("");
  const [collections, setCollections] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [deselectedCollections, setDeselectedCollections] = useState([]);

  const [tagInputTextFocused, setTagInputTextFocused] = useState(false);
  const [tagInputText, setTagInputText] = useState("");
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [deselectedTags, setDeselectedTags] = useState([]);

  const removeSelectedProduct = useCallback(
    (product) => () => {
      const products = [...selectedProducts];
      products.splice(products.indexOf(product), 1);
      setSelectedProducts(products);
    },
    [selectedProducts]
  );

  const updateProductInputText = useCallback(
    (value) => {
      setProductInputText(value);

      if (value === "") {
        setProducts(deselectedProducts);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = products.filter((option) =>
        option.label.match(filterRegex)
      );

      setProducts(resultOptions);
    },
    [deselectedProducts, products]
  );

  const removeSelectedCollection = useCallback(
    (collection) => () => {
      const collections = [...selectedCollections];
      collections.splice(collections.indexOf(collection), 1);
      setSelectedCollections(collections);
    },
    [selectedCollections]
  );

  const updateCollectionInputText = useCallback(
    (value) => {
      setCollectionInputText(value);

      if (value === "") {
        setCollections(deselectedCollections);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = collections.filter((option) =>
        option.label.match(filterRegex)
      );

      setCollections(resultOptions);
    },
    [deselectedCollections, collections]
  );

  const removeSelectedTag = useCallback(
    (tag) => () => {
      const tagsList = [...selectedTags];
      tagsList.splice(tagsList.indexOf(tag), 1);
      setSelectedTags(tagsList);
    },
    [selectedTags]
  );

  const updateTagInputText = useCallback(
    (value) => {
      const cleanValue = value.replace(/\s+/, " ").replace(/[^a-zA-Z\d\s]/, "");
      setTagInputText(cleanValue);

      if (cleanValue === "") {
        setTags(deselectedTags);
        return;
      }

      const filterRegex = new RegExp(cleanValue, "i");
      const resultOptions = deselectedTags.filter((tag) =>
        tag.label.match(filterRegex)
      );

      setTags([
        { value: cleanValue.trim(), label: `${cleanValue.trim()}?` },
        ...resultOptions,
      ]);
    },
    [deselectedTags]
  );

  useEffect(() => {
    const template = state.templates.find(
      (template) => `${template.id}` === props.match.params.id
    );
    if (template) {
      setSelectedProducts(template.selectedProducts || []);
      setSelectedTags(template.selectedTags || []);
      setSelectedCollections(template.selectedCollections || []);
      setActive(template.active || false);
      setSelectedSender(template.selectedSender || "default");
      setselectedSendGridTemplate(template.selectedSendGridTemplate);
      setProductInputText("");
    } else {
      props.history.push("/templates");
    }
  }, [props.match.params, state.templates, props.history]);

  useEffect(() => {
    setDeselectedProducts(
      state.products.map((product) => {
        return {
          value: product.id,
          label: product.title,
        };
      })
    );
    const tagList = [
      ...new Set(
        state.products.flatMap((product) => {
          return product.tags;
        })
      ),
    ];
    setDeselectedTags(
      tagList.map((tag) => {
        return {
          value: tag,
          label: tag,
        };
      })
    );
    const collectionList = [
      ...new Set(
        state.products
          .flatMap((product) => {
            return product.collections;
          })
          .map((coll) => JSON.stringify(coll))
      ),
    ];
    setDeselectedCollections(
      collectionList.map((collStr) => {
        let coll = JSON.parse(collStr);
        return {
          value: coll.id,
          label: coll.title,
        };
      })
    );
  }, [state.products]);

  useEffect(() => {
    const loadsgTemplates = async () => {
      const { templates } = await getTemplates();
      dispatch({ type: "SET_SGTEMPLATES", payload: templates });
    };
    loadsgTemplates();
  }, [dispatch]);

  const productAutocompleteField = (
    <Autocomplete.TextField
      onFocus={() => {
        if (productInputText === "") {
          setProducts(deselectedProducts);
        }
      }}
      onChange={updateProductInputText}
      label="Search products"
      value={productInputText}
      placeholder="search products..."
    />
  );

  const collectionAutocompleteField = (
    <Autocomplete.TextField
      onFocus={() => {
        if (collectionInputText === "") {
          setCollections(deselectedCollections);
        }
      }}
      onChange={updateCollectionInputText}
      label="Search collections"
      value={collectionInputText}
      placeholder="search collections..."
    />
  );

  const tagAutocompleteField = (
    <div
      onKeyDown={(e) => {
        if (e.keyCode === 13) {
          //enter key pressed
          const cleanValue = tagInputText
            .replace(/\s+/, " ")
            .replace(/[^a-zA-Z\d\s]/, "");
          setTagInputText("");
          if (cleanValue && !selectedTags.includes(cleanValue)) {
            setSelectedTags([...selectedTags, cleanValue]);
          }
          setTagInputTextFocused(true);
        }
      }}
    >
      <Autocomplete.TextField
        onFocus={() => {
          if (tagInputText === "") {
            setTags(deselectedTags);
          }
        }}
        onChange={updateTagInputText}
        focused={tagInputTextFocused}
        label="Search tags"
        value={tagInputText}
        placeholder="search tags..."
      />
    </div>
  );

  const templateOptions = state.sgTemplates
    ? state.sgTemplates.map((temp) => {
        return { label: temp.name, value: JSON.stringify(temp) };
      })
    : [];

  const hasSelectedProducts = selectedProducts.length > 0;

  const productsMarkup = hasSelectedProducts
    ? selectedProducts.map((product) => {
        let option = deselectedProducts.find(
          (option) => option.value === product
        );

        const label = option ? option.label : product;
        return (
          <Tag
            key={product}
            onRemove={() => {
              removeSelectedProduct(product)();
              setUnsavedChanges(true);
            }}
          >
            {label}
          </Tag>
        );
      })
    : null;
  const selectedProductMarkup = hasSelectedProducts ? (
    <Stack spacing="extraTight">{productsMarkup}</Stack>
  ) : null;

  const hasSelectedTags = selectedTags.length > 0;

  const tagsMarkup = hasSelectedTags
    ? selectedTags.map((tag) => {
        return (
          <Tag
            key={tag}
            onRemove={() => {
              removeSelectedTag(tag)();
              setUnsavedChanges(true);
            }}
          >
            {tag}
          </Tag>
        );
      })
    : null;
  const selectedTagMarkup = hasSelectedTags ? (
    <Stack spacing="extraTight">{tagsMarkup}</Stack>
  ) : null;

  const hasSelectedCollections = selectedCollections.length > 0;

  const collectionsMarkup = hasSelectedCollections
    ? selectedCollections.map((collection) => {
        let option = deselectedCollections.find(
          (option) => option.value === collection
        );

        const label = option ? option.label : collection;

        return (
          <Tag
            key={collection}
            onRemove={() => {
              removeSelectedCollection(collection)();
              setUnsavedChanges(true);
            }}
          >
            {label}
          </Tag>
        );
      })
    : null;
  const selectedCollectionMarkup = hasSelectedCollections ? (
    <Stack spacing="extraTight">{collectionsMarkup}</Stack>
  ) : null;

  let sgTemplate;
  try {
    sgTemplate = JSON.parse(selectedSendGridTemplate);
  } catch (error) {
    sgTemplate = null;
  }

  const selectedTemplateHasVersions = !sgTemplate
    ? false
    : !sgTemplate.versions
    ? false
    : sgTemplate.versions.length > 0;
  const activeTemplateVersion = selectedTemplateHasVersions
    ? sgTemplate.versions.find((ver) => ver.active)
    : {};
  return (
    <Page
      title={
        "Connector Detail: " +
        (sgTemplate ? sgTemplate.name : "no template selected")
      }
      titleMetadata={
        unsavedChanges ? (
          <Badge status="attention">Unsaved Changes</Badge>
        ) : null
      }
      primaryAction={{
        content: "Save",
        onAction: () => {
          dispatch({
            type: "SET_TEMPLATE",
            payload: {
              id: props.match.params.id,
              data: {
                selectedSendGridTemplate,
                selectedProducts,
                selectedTags,
                selectedCollections,
                selectedSender,
                active,
              },
            },
          });
          dispatch({ type: "SAVE_APPSTATE" });
          setUnsavedChanges(false);
        },
      }}
    >
      <Layout>
        <Layout.Section>
          <Card>
            <Card.Section>
              <SettingToggle
                action={{
                  content: active ? "Disable" : "Enable",
                  onAction: () => {
                    dispatch({
                      type: "SET_TEMPLATE",
                      payload: {
                        id: props.match.params.id,
                        data: {
                          selectedProducts,
                          selectedTags,
                          selectedSender,
                          selectedCollections,
                          selectedSendGridTemplate,
                          active: !active,
                        },
                      },
                    });
                    dispatch({ type: "SAVE_APPSTATE" });
                    setUnsavedChanges(false);
                  },
                }}
                enabled={active}
              >
                {active ? (
                  <p>
                    This template is{" "}
                    <TextStyle variation="strong">enabled</TextStyle> and will
                    trigger messages to be sent.
                  </p>
                ) : (
                  <p>
                    This template is{" "}
                    <TextStyle variation="strong">disabled</TextStyle> and will
                    not trigger messages to be sent.
                  </p>
                )}
              </SettingToggle>
            </Card.Section>
            <Card.Section>
              <FormLayout>
                <Select
                  label={`"From" address`}
                  options={["default", ...state.setup.senderList]}
                  value={selectedSender}
                  onChange={(selectedSender) => {
                    setSelectedSender(selectedSender);
                    setUnsavedChanges(true);
                  }}
                />
                <Select
                  label="Sendgrid Template"
                  options={[
                    {
                      label: "Select a template from Sendgrid...",
                      value: "",
                    },
                    ...templateOptions,
                  ]}
                  value={selectedSendGridTemplate}
                  onChange={(selection) => {
                    setselectedSendGridTemplate(selection);
                    setUnsavedChanges(true);
                  }}
                />

                {sgTemplate ? (
                  <CalloutCard
                    title={sgTemplate.name}
                    primaryAction={{
                      content: "Edit in SendGrid",
                      external: true,
                      url: `https://mc.sendgrid.com/dynamic-templates/${
                        sgTemplate.id
                      }/${
                        selectedTemplateHasVersions
                          ? `version/${activeTemplateVersion.id}/editor`
                          : "versions/new/selector/your-designs"
                      }`,
                    }}
                    illustration={
                      selectedTemplateHasVersions
                        ? activeTemplateVersion.thumbnail_url
                        : ""
                    }
                  >
                    {selectedTemplateHasVersions ? (
                      <div>
                        <p>
                          <TextStyle variation="strong">
                            Active Version:{" "}
                          </TextStyle>
                          {activeTemplateVersion.name}
                        </p>
                        <p>
                          <TextStyle variation="strong">Subject: </TextStyle>
                          {activeTemplateVersion.subject}
                        </p>
                      </div>
                    ) : null}
                  </CalloutCard>
                ) : null}
              </FormLayout>
            </Card.Section>
            <Card.Section title="Linked Products">
              <Stack vertical>
                {selectedProductMarkup}
                <Autocomplete
                  allowMultiple
                  options={products}
                  selected={selectedProducts}
                  textField={productAutocompleteField}
                  onSelect={(e) => {
                    setSelectedProducts(e);
                    setUnsavedChanges(true);
                  }}
                  listTitle="Products"
                />
                <TextStyle variation="subdued">
                  <p>
                    You can hide products from this list by giving them the tag
                    "archived".
                  </p>
                  <p>
                    sorry, currently setting the status to archived does not
                    work.
                  </p>
                </TextStyle>
              </Stack>
            </Card.Section>
            <Card.Section title="Linked Tags">
              <Stack vertical>
                {selectedTagMarkup}
                <Autocomplete
                  allowMultiple
                  options={tags}
                  selected={selectedTags}
                  textField={tagAutocompleteField}
                  onSelect={(e) => {
                    setSelectedTags(e);
                    setUnsavedChanges(true);
                  }}
                  listTitle="Tags"
                />
              </Stack>
            </Card.Section>
            <Card.Section title="Linked Collections">
              <Stack vertical>
                {selectedCollectionMarkup}
                <Autocomplete
                  allowMultiple
                  options={collections}
                  selected={selectedCollections}
                  textField={collectionAutocompleteField}
                  onSelect={(e) => {
                    setSelectedCollections(e);
                    setUnsavedChanges(true);
                  }}
                  listTitle="Collections"
                />
              </Stack>
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
      <Stack distribution="trailing">
        <Modal
          title="Delete Sendgrid Connector"
          open={deleteIntention}
          onClose={() => setDeleteIntention(false)}
          primaryAction={{
            content: "Delete Connector",
            onAction: () => {
              console.log("deleting");
              dispatch({
                type: "DELETE_TEMPLATE",
                payload: props.match.params.id,
              });
              dispatch({ type: "SAVE_APPSTATE" });
              props.history.push("/templates");
            },
            destructive: true,
          }}
        >
          <Modal.Section>
            <TextContainer>
              <p>This action is irreversible!</p>
            </TextContainer>
          </Modal.Section>
        </Modal>
        <Button plain destructive onClick={() => setDeleteIntention(true)}>
          Delete Connector?
        </Button>
      </Stack>
    </Page>
  );
};

export default TemplateDetail;
