import { saveAppState } from "./apiFunctions";

const Reducer = (state, action) => {
  switch (action.type) {
    case "ADD_SENDER":
      return {
        ...state,
        setup: {
          ...state.setup,
          senderList: [...state.setup.senderList, action.payload],
        },
      };
    case "REMOVE_SENDER":
      return {
        ...state,
        setup: {
          ...state.setup,
          senderList: state.setup.senderList.filter(
            (sender) => sender !== action.payload
          ),
        },
      };
    case "SET_SENDER":
      return {
        ...state,
        setup: {
          ...state.setup,
          selectedSender: action.payload,
        },
      };
    case "TOGGLE_MESSAGES":
      return {
        ...state,
        setup: {
          ...state.setup,
          sendMessages: !state.setup.sendMessages,
        },
      };
    case "ERROR_SETUP":
      return {
        ...state,
        setup: {
          ...state.setup,
          error: action.payload,
        },
      };
    case "SET_TEMPLATELIST":
      return {
        ...state,
        templates: action.payload,
      };
    case "SET_ORDERSSENT":
      let sent = [];
      if (action.payload === "All") {
        sent = state.orders.orders.map((order) => order.id);
      } else {
        sent = [...action.payload];
      }
      sent = [...state.orders.sent, ...sent];
      sent = new Set(sent);
      sent = Array.from(sent);
      return {
        ...state,
        orders: {
          ...state.orders,
          sent,
        },
      };
    case "SET_ORDERSUNSENT":
      let unsend = action.payload;
      if (action.payload === "All") {
        unsend = state.orders.orders.map(({ id }) => id);
      }

      return {
        ...state,
        orders: {
          ...state.orders,
          sent: state.orders.sent.filter((order) => !unsend.includes(order)),
        },
      };
    case "SET_ORDERLIST":
      return {
        ...state,
        orders: {
          ...state.orders,
          orders: action.payload,
        },
      };
    case "APPEND_ORDERLIST":
      return {
        ...state,
        orders: {
          ...state.orders,
          orders: [...state.orders.orders, ...action.payload],
        },
      };

    case "SET_SERVERSTATE":
      return {
        ...state,
        setup: {
          ...state.setup,
          ...action.payload.setup,
        },
        orders: {
          ...state.orders,
          sent: action.payload.orders.sent,
        },
        templates: action.payload.templates,
      };

    case "SAVE_APPSTATE":
      saveAppState(state);
      return state;

    case "SET_SHOP":
      return {
        ...state,
        shop: action.payload,
      };

    case "SET_TEMPLATE":
      const templates = [...state.templates];
      const index = templates.findIndex((template) => {
        return template.id === action.payload.id;
      });
      const template = { ...templates[index], ...action.payload.data };
      templates.splice(index, 1, template);

      return {
        ...state,
        templates,
      };

    case "NEW_TEMPLATE":
      return {
        ...state,
        templates: [...state.templates, action.payload],
      };

    case "DELETE_TEMPLATE":
      return {
        ...state,
        templates: state.templates.filter(
          (template) => template.id !== action.payload
        ),
      };

    case "SET_SGTEMPLATES":
      return {
        ...state,
        sgTemplates: action.payload,
      };

    case "SET_ORDEREVENTLOG":
      return {
        ...state,
        orderEventLog: action.payload,
      };

    case "SET_PRODUCTS":
      return {
        ...state,
        products: action.payload,
      };

    case "SET_SETUP":
      return {
        ...state,
        setup: {
          ...state.setup,
          templateDefaults: {
            ...state.setup.templateDefaults,
            ...action.payload.templateDefaults,
          },
        },
      };
    default:
      return state;
  }
};

export default Reducer;
