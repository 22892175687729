import React, { useEffect, useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import { Auth, API } from "aws-amplify";
import querystring from "querystring";
import { Context } from "../data/Store";

const ShopifyCallback = (props) => {
  const [, dispatch] = useContext(Context);
  const [status, setStatus] = useState(<p>pending authorization</p>);

  useEffect(() => {
    const handleAuth = async ({ shop, challenge }) => {
      // sign in with amplify
      dispatch({ type: "SET_SHOP", payload: shop });
      const user = await Auth.signIn(shop);
      await Auth.sendCustomChallengeAnswer(user, challenge);
    };
    const completeAuth = async () => {
      const { location, cookies } = props;
      const path = "/shopify/callback" + location.search;
      const statecookie = cookies.get("state");
      const response = await API.get("shopify", path, {
        headers: { statecookie },
      });
      try {
        if (response.status) {
          setStatus(<p>loading user data</p>);
          await handleAuth({
            shop: querystring.parse(location.search).shop,
            challenge: response.challenge,
          });
          setStatus(<Redirect to={"/templates"} />);
        } else {
          setStatus(<p>authorization failed</p>);
        }
      } catch (e) {
        setStatus(<p>authorization error on client</p>);
        console.log([JSON.stringify(response), JSON.stringify(e)]);
      }
    };
    completeAuth();
  }, [dispatch, props]);

  return status;
};

export default ShopifyCallback;
