import {
  Card,
  Layout,
  Page,
  Modal,
  TextStyle,
  Stack,
  Badge,
  Select,
  Button,
} from "@shopify/polaris";
import React, { useContext, useState, useCallback, useEffect } from "react";
import { formatKidsCampSummary } from "../data/helpers";
import { Context } from "../data/Store";
import {
  processOrderById,
  getOrders,
  getOrderEventLog,
} from "../data/apiFunctions";

const OrderEventLog = (props) => {
  const [state, dispatch] = useContext(Context);
  const [orderSelectOptions, setOrderSelectOptions] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState("");
  const [modalActive, setModalActive] = useState(false);
  const [modalData, setModalData] = useState();

  const toggleModalActive = useCallback(
    () => setModalActive((modalActive) => !modalActive),
    []
  );

  useEffect(() => {
    const initPage = async function () {
      const resp = await getOrders(state.shop, {
        fields: ["meta", "customer"],
        pageSize: 40,
      });
      setOrderSelectOptions(
        resp.map((ord) => {
          return {
            label: `Order ${ord.name} -- ${new Date(
              ord.createdAt
            ).toLocaleDateString([], {
              timeZone: "America/Chicago",
              month: "long",
              day: "numeric",
              weekday: "short",
            })} ${
              ord.customer
                ? `-- ${ord.customer.firstName} ${ord.customer.lastName}`
                : ""
            }`,
            value: ord.id,
          };
        })
      );
    };
    initPage();
  }, [state.shop]);
  return (
    <Page title="Processed Orders">
      <Layout>
        <Layout.Section>
          <Card title="Manually process an order">
            <Card.Section>
              <Stack>
                <Stack.Item fill>
                  <Select
                    options={[
                      {
                        label: "select an order...",
                        value: "",
                        disabled: true,
                      },
                      ...orderSelectOptions,
                    ]}
                    onChange={setSelectedOrder}
                    value={selectedOrder}
                  />
                </Stack.Item>
                <Stack.Item>
                  <Button
                    onClick={async () => {
                      await processOrderById(state.shop, selectedOrder);
                      setSelectedOrder("");
                      setTimeout(async () => {
                        const events = await getOrderEventLog(state.shop);
                        dispatch({
                          type: "SET_ORDEREVENTLOG",
                          payload: events.reverse(),
                        });
                      }, 3000);
                    }}
                  >
                    Process Order
                  </Button>
                </Stack.Item>
              </Stack>
            </Card.Section>
          </Card>
        </Layout.Section>
        <Layout.Section>
          {state.orderEventLog.map((record) => {
            const orderData = record.orderData
              ? JSON.parse(record.orderData)
              : null;
            const messagesSent = record.messagesSent
              ? JSON.parse(record.messagesSent)
              : null;
            let popRetryMessageModal, kidsCampSummary;

            if (orderData) {
              kidsCampSummary = formatKidsCampSummary(orderData.lineItems);
              popRetryMessageModal = () => {
                setModalData({
                  ...record,
                  orderData,
                  kidsCampSummary,
                  messagesSent,
                });
                toggleModalActive();
              };
            }
            return (
              <Card
                key={record.orderId}
                title={
                  orderData
                    ? `Order ${orderData.name} -- ${new Date(
                        orderData.createdAt
                      ).toLocaleDateString([], {
                        timeZone: "America/Chicago",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        weekday: "short",
                        hour: "numeric",
                        minute: "numeric",
                      })}`
                    : "No Order Data Found"
                }
                actions={[
                  {
                    content: "retry send message",
                    onAction: popRetryMessageModal,
                  },
                ]}
              >
                {orderData && (
                  <>
                    <Card.Section>
                      <Stack>
                        <div>
                          <p>
                            <TextStyle variation="strong">
                              Customer Name:{" "}
                            </TextStyle>{" "}
                            {orderData.customer.firstName +
                              " " +
                              orderData.customer.lastName}
                          </p>
                          <p>
                            <TextStyle variation="strong">Email: </TextStyle>
                            {orderData.customer.email}
                          </p>
                        </div>

                        <div>
                          <TextStyle variation="strong">
                            Messages Sent:{" "}
                          </TextStyle>
                          {messagesSent.map(({ template }) => {
                            let sgTemplate;
                            if (
                              typeof template.selectedSendGridTemplate ===
                              "string"
                            ) {
                              sgTemplate = JSON.parse(
                                template.selectedSendGridTemplate
                              ).versions.find(({ active }) => active);
                            } else {
                              sgTemplate = template.selectedSendGridTemplate.versions.find(
                                ({ active }) => active
                              );
                            }
                            return (
                              <Badge key={sgTemplate.id}>
                                <TextStyle variation="strong">
                                  {sgTemplate.name + ": "}
                                </TextStyle>
                                {sgTemplate.subject}
                              </Badge>
                            );
                          })}
                        </div>
                      </Stack>
                    </Card.Section>
                    {!kidsCampSummary ? null : kidsCampSummary !==
                      orderData.kidsCampSummary ? (
                      <Card.Section title="Child Registration Summary (Possible registration summary mismatch)">
                        <Stack>
                          <Stack vertical>
                            <TextStyle variation="strong">
                              (summary at time of order)
                            </TextStyle>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: orderData.kidsCampSummary,
                              }}
                            ></div>
                          </Stack>
                          <Stack vertical>
                            <TextStyle variation="strong">
                              (calculated summary)
                            </TextStyle>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: kidsCampSummary,
                              }}
                            ></div>
                          </Stack>
                        </Stack>
                      </Card.Section>
                    ) : (
                      <Card.Section title="Child Registration Summary">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: orderData.kidsCampSummary,
                          }}
                        ></div>
                      </Card.Section>
                    )}
                  </>
                )}
              </Card>
            );
          })}
        </Layout.Section>
      </Layout>
      <Modal
        open={modalActive}
        onClose={toggleModalActive}
        accessibilityLabel="retry sending order message"
        title="Resend all messages for this order?"
        primaryAction={{
          content: "Resend Messages",
          onAction: () => {
            processOrderById(state.shop, modalData.orderId);
            setTimeout(async () => {
              const events = await getOrderEventLog(state.shop);
              dispatch({
                type: "SET_ORDEREVENTLOG",
                payload: events.reverse(),
              });
            }, 3000);
            toggleModalActive();
          },
        }}
      >
        Are you sure you want to retry to send this order?
      </Modal>
    </Page>
  );
};

export default OrderEventLog;
