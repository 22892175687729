const dev = {
  shopifyApiKey: "10f8bc67cc4a1e5e04d635b718c0bc80",

  apiGateway: {
    REGION: "us-east-1",
    URL: "https://ji7rfj6je0.execute-api.us-east-1.amazonaws.com/dev",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_bVfuCNYu6",
    APP_CLIENT_ID: "5s5bldif4v3ga2su8le1dpuo5j",
    IDENTITY_POOL_ID: "us-east-1:01e13505-464d-431f-81db-50161143bb0f",
  },
};

const prod = {
  shopifyApiKey: "e4f35148fac5de31d5539a49e2257918",
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://tcdj7eji41.execute-api.us-east-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_EiVhebmK0",
    APP_CLIENT_ID: "5lvc94t6v41kgb4b1i6jv0d73n",
    IDENTITY_POOL_ID: "us-east-1:3d6091de-e3e5-4394-844f-1b7acb500a7e",
  },
};
// Default to dev if not set
const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;
export default {
  // Add common config values here
  tinyMceApiKey: "mk7z80yb7tcq8alz1lr45e3oh0h3fz47fknu9hky84agooju",
  ...config,
};
