import React, { Component } from "react";
import { Link, useRouteMatch } from "react-router-dom";

const TabLink = (link) => {
  let match = useRouteMatch({
    path: link.to,
    exact: true,
  });
  return (
    <li className="Polaris-Tabs__TabContainer">
      <div
        className={
          "Polaris-Tabs__Tab " + (match ? "Polaris-Tabs__Tab--selected" : "")
        }
      >
        <Link to={link.to} style={{ textDecoration: "none" }}>
          <span className="Polaris-Tabs__Title">{link.label}</span>
        </Link>
      </div>
    </li>
  );
};

class NavTabs extends Component {
  state = {
    navLinks: [
      {
        to: "/setup",
        label: "Setup",
      },
      { to: "/templates", label: "Sendgrid Connectors" },
      { to: "/testing-data", label: "Testing Data" },
      { to: "/processed-orders", label: "Processed Orders" },
    ],
  };

  render() {
    return (
      <div className="Polaris-Tabs__Wrapper">
        <ul role="tablist" className="Polaris-Tabs">
          {this.state.navLinks.map((link) => (
            <TabLink {...link} key={link.to} />
          ))}
        </ul>
      </div>
    );
  }
}

export default NavTabs;
