import React from "react";
import { Page, Layout, Card } from "@shopify/polaris";
import ReactJson from "react-json-view";

const TestingData = (props) => {
  return (
    <Page title="Testing Data" subtitle="click clipboard to copy">
      <Layout>
        <Layout.Section>
          <Card>
            <ReactJson
              displayDataTypes={false}
              displayObjectSize={false}
              displayArrayKey={false}
              src={{
                id: "gid://shopify/Order/2052888297570",
                name: "#2163",
                createdAt: "2020-03-09T19:26:39Z",
                customer: {
                  email: "zeke@articulture.org",
                  firstName: "Jennifer",
                  lastName: "Sample",
                  validEmailAddress: true,
                  note: null,
                  verifiedEmail: true,
                },
                lineItems: [
                  {
                    id: "gid://shopify/LineItem/4502376874082",
                    name: "Extended Care - Morning",
                    variant: {
                      id: "gid://shopify/ProductVariant/20454692061282",
                      title: "Morning",
                      displayName: "Extended Care - Morning",
                      product: {
                        id: "gid://shopify/Product/2235602174050",
                        title: "Extended Care",
                        productType: "",
                        tags: [],
                      },
                    },
                    customAttributes: [
                      { key: "day", value: "Thursday" },
                      { key: "student_no", value: "student-1" },
                      { key: "class", value: "variant-31273439035490" },
                      { key: "student", value: "Bastian" },
                    ],
                  },
                  {
                    id: "gid://shopify/LineItem/4502376939618",
                    name: "Extended Care - Morning",
                    variant: {
                      id: "gid://shopify/ProductVariant/20454692061282",
                      title: "Morning",
                      displayName: "Extended Care - Morning",
                      product: {
                        id: "gid://shopify/Product/2235602174050",
                        title: "Extended Care",
                        productType: "",
                        tags: [],
                      },
                    },
                    customAttributes: [
                      { key: "day", value: "Wednesday" },
                      { key: "student_no", value: "student-1" },
                      { key: "class", value: "variant-31273439035490" },
                      { key: "student", value: "Bastian" },
                    ],
                  },
                  {
                    id: "gid://shopify/LineItem/4502376972386",
                    name: "Extended Care - Morning",
                    variant: {
                      id: "gid://shopify/ProductVariant/20454692061282",
                      title: "Morning",
                      displayName: "Extended Care - Morning",
                      product: {
                        id: "gid://shopify/Product/2235602174050",
                        title: "Extended Care",
                        productType: "",
                        tags: [],
                      },
                    },
                    customAttributes: [
                      { key: "day", value: "Tuesday" },
                      { key: "student_no", value: "student-1" },
                      { key: "class", value: "variant-31273439035490" },
                      { key: "student", value: "Bastian" },
                    ],
                  },
                  {
                    id: "gid://shopify/LineItem/4502377005154",
                    name: "Extended Care - Morning",
                    variant: {
                      id: "gid://shopify/ProductVariant/20454692061282",
                      title: "Morning",
                      displayName: "Extended Care - Morning",
                      product: {
                        id: "gid://shopify/Product/2235602174050",
                        title: "Extended Care",
                        productType: "",
                        tags: [],
                      },
                    },
                    customAttributes: [
                      { key: "day", value: "Monday" },
                      { key: "student_no", value: "student-1" },
                      { key: "class", value: "variant-31273439035490" },
                      { key: "student", value: "Bastian" },
                    ],
                  },
                  {
                    id: "gid://shopify/LineItem/4502377037922",
                    name: "Extended Care - Morning",
                    variant: {
                      id: "gid://shopify/ProductVariant/20454692061282",
                      title: "Morning",
                      displayName: "Extended Care - Morning",
                      product: {
                        id: "gid://shopify/Product/2235602174050",
                        title: "Extended Care",
                        productType: "",
                        tags: [],
                      },
                    },
                    customAttributes: [
                      { key: "day", value: "Thursday" },
                      { key: "student_no", value: "student-1" },
                      { key: "class", value: "variant-31272187199586" },
                      { key: "student", value: "Sofie" },
                    ],
                  },
                  {
                    id: "gid://shopify/LineItem/4502377070690",
                    name: "Extended Care - Morning",
                    variant: {
                      id: "gid://shopify/ProductVariant/20454692061282",
                      title: "Morning",
                      displayName: "Extended Care - Morning",
                      product: {
                        id: "gid://shopify/Product/2235602174050",
                        title: "Extended Care",
                        productType: "",
                        tags: [],
                      },
                    },
                    customAttributes: [
                      { key: "day", value: "Wednesday" },
                      { key: "student_no", value: "student-1" },
                      { key: "class", value: "variant-31272187199586" },
                      { key: "student", value: "Sofie" },
                    ],
                  },
                  {
                    id: "gid://shopify/LineItem/4502377103458",
                    name: "Extended Care - Morning",
                    variant: {
                      id: "gid://shopify/ProductVariant/20454692061282",
                      title: "Morning",
                      displayName: "Extended Care - Morning",
                      product: {
                        id: "gid://shopify/Product/2235602174050",
                        title: "Extended Care",
                        productType: "",
                        tags: [],
                      },
                    },
                    customAttributes: [
                      { key: "day", value: "Tuesday" },
                      { key: "student_no", value: "student-1" },
                      { key: "class", value: "variant-31272187199586" },
                      { key: "student", value: "Sofie" },
                    ],
                  },
                  {
                    id: "gid://shopify/LineItem/4502377136226",
                    name: "Extended Care - Morning",
                    variant: {
                      id: "gid://shopify/ProductVariant/20454692061282",
                      title: "Morning",
                      displayName: "Extended Care - Morning",
                      product: {
                        id: "gid://shopify/Product/2235602174050",
                        title: "Extended Care",
                        productType: "",
                        tags: [],
                      },
                    },
                    customAttributes: [
                      { key: "day", value: "Monday" },
                      { key: "student_no", value: "student-1" },
                      { key: "class", value: "variant-31272187199586" },
                      { key: "student", value: "Sofie" },
                    ],
                  },
                  {
                    id: "gid://shopify/LineItem/4502377168994",
                    name: "Art of Food | August 17 - 21 | Grades 3 - 6",
                    variant: {
                      id: "gid://shopify/ProductVariant/31272187199586",
                      title: "Default Title",
                      displayName:
                        "Art of Food | August 17 - 21 | Grades 3 - 6 - Default Title",
                      product: {
                        id: "gid://shopify/Product/4357283741794",
                        title: "Art of Food | August 17 - 21 | Grades 3 - 6",
                        productType: "Children's Classes",
                        tags: ["children"],
                      },
                    },
                    customAttributes: [
                      { key: "student-name1", value: "Sofie" },
                      { key: "student-last-name1", value: "Sample" },
                      { key: "student-age1", value: "11" },
                    ],
                  },
                  {
                    id: "gid://shopify/LineItem/4502377201762",
                    name: "Game On! | June 29 - July 2 | Grades 2-6 - Full-Day",
                    variant: {
                      id: "gid://shopify/ProductVariant/31273439035490",
                      title: "Full-Day",
                      displayName:
                        "Game On! | June 29 - July 2 | Grades 2-6 - Full-Day",
                      product: {
                        id: "gid://shopify/Product/4918751854637",
                        title: "Game On! | June 29 - July 2 | Grades 2-6",
                        productType: "Children's Classes",
                        tags: ["children"],
                      },
                    },
                    customAttributes: [
                      { key: "student-name1", value: "Bastian" },
                      { key: "student-last-name1", value: "Sample" },
                      { key: "student-age1", value: "7" },
                      { key: "student-notes1", value: "No dairy products" },
                    ],
                  },
                  {
                    id: "gid://shopify/LineItem/4502377234530",
                    name: "Game On! | June 29 - July 2 | Grades 2-6 - Full-Day",
                    variant: {
                      id: "gid://shopify/ProductVariant/31273439035490",
                      title: "Full-Day",
                      displayName:
                        "Game On! | June 29 - July 2 | Grades 2-6 - Full-Day",
                      product: {
                        id: "gid://shopify/Product/4918751854637",
                        title: "Game On! | June 29 - July 2 | Grades 2-6",
                        productType: "Children's Classes",
                        tags: ["children"],
                      },
                    },
                    customAttributes: [
                      { key: "student-name1", value: "Sofie" },
                      { key: "student-last-name1", value: "Sample" },
                      { key: "student-age1", value: "11" },
                    ],
                  },
                ],
                kidsCampSummary:
                  "<h3>\n Student: \n <strong>Sofie Sample</strong>\n , age: <strong>11</strong>\n \n <br/>\n </h3>\n <h4>\n Sofie is registered for:\n </h4>\n \n \n <strong>Art of Food | August 17 - 21 | Grades 3 - 6</strong>\n <br />\n Extended Care Days:\n <br /> Monday Morning, Tuesday Morning, Wednesday Morning, Thursday Morning\n \n <br /><br />\n <strong>Game On! | June 29 - July 2 | Grades 2-6 - Full-Day</strong>\n \n <br />\n <h3>\n Student: \n <strong>Bastian Sample</strong>\n , age: <strong>7</strong>\n , notes: <strong>No dairy products</strong>\n <br/>\n </h3>\n <h4>\n Bastian is registered for:\n </h4>\n \n \n <strong>Game On! | June 29 - July 2 | Grades 2-6 - Full-Day</strong>\n <br />\n Extended Care Days:\n <br /> Monday Morning, Tuesday Morning, Wednesday Morning, Thursday Morning",
              }}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default TestingData;
