import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";

const initialState = {
  shop: "silly-string-ultd.myshopify.com",
  setup: {
    senderList: [],
    selectedSender: "",
    sendMessages: false,
  },
  orders: {
    orders: [],
    selected: [],
    sent: [],
  },
  products: [],
  templates: [
    {
      id: "0",
      selectedSendGridTemplate: '""',
      selectedProducts: [],
      selectedTags: [],
      selectedCollections: [],
      active: false,
    },
  ],
  sgTemplates: [],
  orderEventLog: [],
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);
export default Store;
