import { API } from "aws-amplify";

export const saveAppState = async (state) => {
  const {
    shop,
    setup,
    orders: { sent },
    templates,
  } = state;

  await API.post("shopify", `/settings?shop=${shop}`, {
    body: {
      appState: {
        setup,
        orders: { sent },
        templates,
      },
    },
  });
};

export const getTemplates = function () {
  return API.get("shopify", `/templates`);
};

export const getOrders = function (shop, params) {
  return API.post(
    "shopify",
    `/orders?shop=${shop.match(/.*(?=\.myshopify\.com)/)[0]}`,
    { body: params }
  );
};

export const getOrderEventLog = function (shop) {
  return API.get(
    "shopify",
    `/order-log?shop=${shop.match(/.*(?=\.myshopify\.com)/)[0]}`
  );
};

export const processOrderById = function (
  shop,
  orderId,
  options = { testMessage: false }
) {
  return API.post(
    "shopify",
    `/process-order?shop=${
      shop.match(/.*(?=\.myshopify\.com)/)[0]
    }&orderid=${orderId}${options.testMessage ? "&test=true" : ""}`,
    { body: {} }
  );
};
