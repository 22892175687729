import {
  Badge,
  Card,
  Heading,
  Icon,
  Layout,
  Page,
  ResourceItem,
  ResourceList,
  Stack,
} from "@shopify/polaris";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "../data/Store";
import { v4 as uuid } from "uuid";
import {
  BugMajorMonotone,
  EnvelopeMajorMonotone,
} from "@shopify/polaris-icons";

const TemplateList = (props) => {
  const [state, dispatch] = useContext(Context);

  const createTemplate = () => {
    const newId = uuid();
    const newTemplate = {
      ...state.setup.templateDefaults,
      id: newId,
    };
    dispatch({ type: "NEW_TEMPLATE", payload: newTemplate });
    dispatch({ type: "SAVE_APPSTATE" });
    props.history.push(`/templates/${newId}`);
  };

  return (
    <Page
      title="Sendgrid Connectors"
      primaryAction={{
        content: "Create Template",
        onAction: createTemplate,
      }}
    >
      <Layout>
        <Layout.Section>
          <Card>
            <ResourceList
              items={state.templates}
              renderItem={({ id, selectedSendGridTemplate, active }) => {
                let sgTemplate;
                try {
                  sgTemplate = JSON.parse(selectedSendGridTemplate);
                } catch (e) {
                  sgTemplate = { name: "! no connected template" };
                }
                const hasActiveVersion = !sgTemplate
                  ? false
                  : !sgTemplate.versions
                  ? false
                  : sgTemplate.versions.length < 1
                  ? false
                  : true;

                const activeVersion = hasActiveVersion
                  ? sgTemplate.versions.find((ver) => ver.active)
                  : null;
                return (
                  <ResourceItem id={id}>
                    <Link
                      to={`/templates/${id}`}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <Stack distribution="equalSpacing">
                        {activeVersion ? (
                          <div>
                            <Heading>
                              {sgTemplate.name + ": " + activeVersion.name}
                            </Heading>
                            <Stack>
                              <Icon source={EnvelopeMajorMonotone} />
                              <p>{activeVersion.subject}</p>
                            </Stack>
                          </div>
                        ) : (
                          <div>
                            <Heading>{sgTemplate.name}</Heading>
                            <Stack>
                              <Icon source={BugMajorMonotone} />
                              <p>Connected template has no designs</p>{" "}
                            </Stack>
                          </div>
                        )}
                        <Badge status={active ? "success" : "new"}>
                          {active ? "Connector active" : "Connector disabled"}
                        </Badge>
                      </Stack>
                    </Link>
                  </ResourceItem>
                );
              }}
            ></ResourceList>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default TemplateList;
