import _ from "lodash";

function mapDaytoNumber(dayName) {
  return [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ].indexOf(dayName);
}

export function formatKidsCampSummary(lineItems) {
  //check for kids items on order
  if (
    !lineItems.filter(({ variant }) => {
      return variant.product.productType.match(
        /(children|toddler|teen|release)/i
      );
    }).length > 0
  ) {
    return "";
  } else {
    //if there are kids items then we'll return the html for the email
    let hasStudents = false;
    let hasStudentCare = false;
    let students = [];

    //mapping the lineitems
    const itemMap = lineItems.map((item) => {
      let student = {};
      let studentCare = {};
      let hasStudent = false;
      let hasCare = false;

      if (item.customAttributes.length > 0) {
        if (item.customAttributes[0].key === "student-name1")
          item.customAttributes.map((att) => {
            student[att.key] = att.value.trim();
            hasStudents = true;
            hasStudent = true;
            return null;
          });

        if (item.customAttributes[0].key === "day") {
          studentCare["time"] = item.variant.title;
          item.customAttributes.map((att) => {
            studentCare[att.key] = att.value;
            if (att.key === "day")
              studentCare["sortDay"] = mapDaytoNumber(att.value);
            hasStudentCare = true;
            hasCare = true;
            return null;
          });
        }
      }

      // return the mapped lineitem
      return {
        ...item,
        student: hasStudent ? student : null,
        studentCare: hasCare ? studentCare : null,
      };
    });

    if (hasStudents) {
      // get unique students and update the students array for the order
      students = itemMap.map((item) => {
        return item.student;
      });
      students = students.filter((student, index) => {
        return (
          student && index === students.findIndex((s) => _.isEqual(s, student))
        );
      });

      // go through line items to assign classes and care to students
      students = students.map((student) => {
        //find the classes that match this student
        let classes = itemMap
          .filter((item) => _.isEqual(item.student, student))
          .map((item) => {
            // map the class object
            const variantId = item.variant.id.match(/(?<=\/)\d+$/);

            let careDays = [];
            if (hasStudentCare) {
              itemMap
                .filter((item) => {
                  return (
                    item.studentCare &&
                    item.studentCare.class === `variant-${variantId}` &&
                    item.studentCare.student === student["student-name1"]
                  );
                })
                .map((item) => {
                  careDays.push({
                    day: item.studentCare.day,
                    time: item.studentCare.time,
                    sortDay: item.studentCare.sortDay,
                  });
                  return null;
                });
            }

            return {
              variantId,
              name: item.name,
              careDays: _.sortBy(careDays, (care) => care.sortDay),
            };
          });

        return { ...student, classes };
      });

      //go through line items again to assign student care to
    }

    let formattedStudentSummary = "";
    let secondStudent = false;
    for (let student of students) {
      let studentString = `${
        secondStudent ? "<br />" : ""
      }<h3>Student: <strong>${
        student["student-name1"] + " " + student["student-last-name1"]
      }</strong>, age: <strong>${student["student-age1"]}</strong>${
        student["student-notes1"]
          ? `, notes: <strong>${student["student-notes1"]}</strong>`
          : ""
      }</h3>`;

      let newLine = false;
      for (let thisClass of student.classes) {
        let classString = `${newLine ? "<br /><br />" : ""} <strong>${
          thisClass.name
        }</strong> ${
          thisClass.careDays.length > 0
            ? `<br /> Extended Care Days: ${thisClass.careDays.reduce(
                (days, day, index, src) =>
                  index === src.length - 1
                    ? days + " " + day.day + " " + day.time
                    : days + " " + day.day + " " + day.time + ",",
                "<br />"
              )}`
            : ""
        }`;
        studentString += classString;
        newLine = true;
      }
      formattedStudentSummary += studentString;
      secondStudent = true;
    }

    return formattedStudentSummary.trim();
  }
}
