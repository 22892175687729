import React, { useContext, useState } from "react";
import {
  Page,
  Layout,
  FormLayout,
  Card,
  TextField,
  Select,
  Banner,
  SettingToggle,
  TextStyle,
} from "@shopify/polaris";
import { Context } from "../data/Store";

const Setup = (props) => {
  const [state, dispatch] = useContext(Context);
  const [unverifiedAddress, setUnverifiedAddress] = useState("");

  function verifySender(senderAddress) {
    setUnverifiedAddress("");
    dispatch({ type: "ADD_SENDER", payload: senderAddress });
    dispatch({ type: "SET_SENDER", payload: senderAddress });
    dispatch({ type: "SAVE_APPSTATE" });
  }

  return (
    <Page title="Setup">
      <Layout>
        <Layout.Section>
          <Card sectioned>
            {state.setup.error ? (
              <Banner status="warning">{state.setup.error}</Banner>
            ) : null}
            <Card.Section title="Email Settings">
              <FormLayout>
                <SettingToggle
                  action={{
                    content: state.setup.sendMessages ? "Disable" : "Enable",
                    onAction: () => {
                      dispatch({ type: "TOGGLE_MESSAGES" });
                      dispatch({ type: "SAVE_APPSTATE" });
                    },
                  }}
                  enabled={state.setup.sendMessages}
                >
                  {state.setup.sendMessages ? (
                    <p>
                      Currently{" "}
                      <TextStyle variation="strong">sending</TextStyle> messages
                    </p>
                  ) : (
                    <p>
                      Currently{" "}
                      <TextStyle variation="strong">not sending</TextStyle>{" "}
                      messages
                    </p>
                  )}
                </SettingToggle>
                <Select
                  label={`Select a default "From" address`}
                  options={state.setup.senderList}
                  value={state.setup.selectedSender}
                  onChange={(selectedSender) => {
                    dispatch({ type: "SET_SENDER", payload: selectedSender });
                    dispatch({ type: "SAVE_APPSTATE" });
                  }}
                  labelAction={{
                    content: "delete sender address",
                    destructive: true,
                    onAction: () => {
                      dispatch({
                        type: "REMOVE_SENDER",
                        payload: state.setup.selectedSender,
                      });
                      dispatch({
                        type: "SET_SENDER",
                        payload: state.setup.senderList[0] || "",
                      });
                      dispatch({ type: "SAVE_APPSTATE" });
                    },
                  }}
                />
                <TextField
                  label={`Add a new "From" address`}
                  type="email"
                  placeholder="example@articulture.org"
                  value={unverifiedAddress}
                  onChange={setUnverifiedAddress}
                  labelAction={{
                    content: "add address",
                    onAction: () => verifySender(unverifiedAddress),
                  }}
                />
              </FormLayout>
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default Setup;
