import React, { useContext, useEffect, useCallback } from "react";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import { withCookies } from "react-cookie";
import { API } from "aws-amplify";
import querystring from "query-string";

import Shopify from "./components/Shopify";
import ShopifyCallback from "./components/ShopifyCallback";

import Setup from "./components/Setup";
import NavTabs from "./components/NavTabs";

import { Context } from "./data/Store";
import TemplateList from "./components/TemplateList";
import TemplateDetail from "./components/TemplateDetail";
import TestingData from "./components/TestingData";
import OrderEventLog from "./components/OrderEventLog";

import { getOrderEventLog } from "./data/apiFunctions";

const App = (props) => {
  const [state, dispatch] = useContext(Context);

  const getOrderLog = useCallback(
    (shop) => async () => {
      //get the log
      const events = await getOrderEventLog(shop);
      dispatch({ type: "SET_ORDEREVENTLOG", payload: events.reverse() });
    },
    [dispatch]
  );

  const getProducts = useCallback(
    (shop) => async () => {
      //get all the products
      function formatProducts(products) {
        return products.data.products.edges.map(({ cursor, node }) => {
          //mapping the product object

          return {
            cursor,
            ...node,
            collections: node.collections
              ? node.collections.edges.map(({ node }) => {
                  return node;
                })
              : [],
          };
        });
      }
      let params = {
        shop,
        pagesize: 100,
        filter: "NOT tag:archived",
      };

      let products = [];
      let hasNextPage = true;
      let cursor = null;

      while (hasNextPage) {
        if (cursor) {
          params = {
            ...params,
            cursor,
          };
        }
        try {
          const newProducts = await API.get(
            "shopify",
            `/products?${querystring.stringify(params)}`
          );
          products = [...products, ...formatProducts(newProducts)];
          cursor = products[products.length - 1].cursor;
          hasNextPage = newProducts.data.products.pageInfo.hasNextPage;
          dispatch({
            type: "SET_PRODUCTS",
            payload: products,
          });
          await new Promise((resolve) => setTimeout(resolve, 2000));
        } catch (e) {
          if (e.response && e.response.data.code === "THROTTLED") {
            await new Promise((resolve) => setTimeout(resolve, 6000));
          } else {
            console.log(e);
            break;
          }
        }
      }
    },
    [dispatch]
  );

  useEffect(() => {
    const initAppState = async () => {
      const settings = await API.get("shopify", `/settings?shop=${state.shop}`);
      if (settings) {
        dispatch({ type: "SET_SERVERSTATE", payload: settings });
      }
      getOrderLog(state.shop)();
      getProducts(state.shop)();
    };
    initAppState();
  }, [getProducts, getOrderLog, state.shop, dispatch]);

  return (
    <div className="App">
      <NavTabs />
      <Switch>
        <Route
          path="/shopify/callback"
          render={({ location }) => (
            <ShopifyCallback location={location} cookies={props.cookies} />
          )}
        />
        <Route
          path="/shopify"
          render={({ location }) => (
            <Shopify cookies={props.cookies} location={location} />
          )}
        />
        <Route path="/setup" render={() => <Setup />} />
        <Route
          path="/templates/:id"
          render={({ match, history }) => (
            <TemplateDetail match={match} history={history} />
          )}
        />

        <Route
          path="/templates"
          render={({ history }) => <TemplateList history={history} />}
        />
        <Route path="/testing-data" render={() => <TestingData />} />
        <Route path="/processed-orders" render={() => <OrderEventLog />} />
      </Switch>
    </div>
  );
};

export default withRouter(withCookies(App));
